import React, { useEffect } from 'react';
import {Header} from '../components/Header';
import {Footer} from '../components/Footer';
import Confirmation from '../components/strapi/contact/Confirmation';

function PageKontaktBestaetigung() { 
  useEffect(() => {
    document.title = "Kontakt Bestaetigung"
  }, []);
  return (
      <div>
        <Header />
          <Confirmation />
        <Footer />  
      </div>
  );
}

export default PageKontaktBestaetigung;