import {
  LPGebaeudeEnergieGesetz,
  Page404,
  PageAboutUs,
  PageAGB,
  PageAssemblyPartner,
  PageCareer,
  PageConsulting,
  PageContact,
  PageEKB,
  PageHome,
  PageImpressum,
  PagePrinciple,
  PagePrivacy,
  PageProjects,
  PageSingleProject,
  PageSolutions
} from '../pages'

import PageKontaktBestaetigung from '../pages/PageKontaktBestaetigung'

export const routes = [
  { component: PageSolutions, path: '/loesungen/:slug' },
  { component: PageSolutions, path: '/loesungen' },
  { component: PagePrinciple, path: '/das-prinzip' },
  { component: PageConsulting, path: '/consulting' },
  { component: PageSingleProject, path: '/projekte/:slug' },
  { component: PageProjects, path: '/projekte' },
  { component: PageAboutUs, path: '/ueber-uns' },
  { component: PageCareer, path: '/karriere' },
  { component: PageContact, path: '/kontakt' },
  { component: PageAssemblyPartner, path: '/montagepartner' },

  // footer menu
  { component: PageImpressum, path: '/impressum' },
  { component: PagePrivacy, path: '/datenschutz' },
  { component: PageAGB, path: '/agb' },
  { component: PageEKB, path: '/ekb' },

  // landing pages
  // when changing, also change in Footer.jsx and BigPic.jsx
  { component: LPGebaeudeEnergieGesetz, path: '/geg' },

  //Kontakt Bestaetigung
  { component: PageKontaktBestaetigung, path: '/kontakt-bestaetigung'},

  // homepage
  { component: PageHome, path: '/home' },
  { component: PageHome, path: '/' },

  // 404
  { component: Page404, path: '/404' }
]
