import React from "react";
import { Typo20RTF, Typo32 } from "../../TYPO";
import { ContentNarrow } from "../../wrapper/ContentNarrow";
import { Stack, Box } from "@mui/material";

function Confirmation(){
  return(
    <ContentNarrow>
      <Stack sx={{ 
        textAlign: 'center', 
        maxWidth: '100%', 
        paddingTop: '225px', 
        paddingBottom: '150px'
        }}>
        <Box>
          <Typo32>
            Vielen Dank für Ihre Kontaktanfrage.  
          </Typo32>
        </Box>
        <Box>
          <Typo20RTF>
            Wir werden uns umgehend mit Ihnen in Verbindung setzen.  
          </Typo20RTF>
        </Box>
      </Stack>
    </ContentNarrow>
  );
}

export default Confirmation;