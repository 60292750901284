import { keyframes, useTheme } from '@emotion/react'
import {
  Button,
  Container,
  Stack,
  useMediaQuery
} from '@mui/material'
import Grid from '@mui/system/Unstable_Grid'
import Box from '@mui/system/Box'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DZ_GotoPage, SVGPic, Typo20, Typo26, Typo32, Typo26Bold } from '../../'
import { useOnScreen } from '../../../hooks'
import { margin } from '@mui/system'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

// animations

/*
const wobbleAnimation = keyframes`
    0 %  { transform: translate(1px, 1px)   rotate(0deg)   },
    10%  { transform: translate(-1px, -2px) rotate(-1deg)  },
    20%  { transform: translate(-3px, 0px)  rotate(1deg)   },
    30%  { transform: translate(3px, 2px)   rotate(0deg)   },
    40%  { transform: translate(1px, -1px)  rotate(1deg)   },
    50%  { transform: translate(-1px, 2px)  rotate(-1deg)  },
    60%  { transform: translate(-3px, 1px)  rotate(0deg)   },
    70%  { transform: translate(3px, 1px)   rotate(-1deg)  },
    80%  { transform: translate(-1px, -1px) rotate(1deg)   },
    90%  { transform: translate(1px, 2px)   rotate(0deg)   },
    100% { transform: translate(1px, -2px)  rotate(-1deg)  }
`
*/

const moveInFromLeftAnimation = keyframes`
    0 %  { transform: translate(-200px, 0px) },
    100% { transform: translate(200px, 0px) }
`

const moveInFromRightAnimation = keyframes`
    0 %  { transform: translate(0px, 0px) },
    100% { transform: translate(-400px, 0px) }
`

/*
const bounceAnimation = keyframes`
  from, 20%, 53%, 80%, to { transform: translate3d(0, 0, 0); }
  40%, 43% { transform: translate3d(0, -30px, 0); }
  70% { transform: translate3d(0, -15px, 0); }
  90% { transform: translate3d(0, -4px, 0); }
`
*/

const SolutionText = ({ href, url, size, question, description, title }) => {
  const isGtMD = useMediaQuery(useTheme().breakpoints.up('md'))

  return (
    <Stack alignItems={'start'} justifyContent={'center'}>
      {isGtMD && <SVGPic src={`${BASE_URL}${url}`} size={size} />}

      <Typo32 sx={{ maxWidth: 500 }}>{question}</Typo32>
      <Typo20 sx={{ maxWidth: 500, mb: 2 }}>{description}</Typo20>

      <DZ_GotoPage href={`/loesungen${href}`} title={title} />
    </Stack>
  )
}
SolutionText.propTypes = {
  href: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

const HomeSolutionsDesktop = ({ data }) => {
  const history = useHistory()
  const refL = useRef()
  const refR = useRef()
  const isOnScreenL = useOnScreen(refL)
  const isOnScreenR = useOnScreen(refR)

  const [btn2hover, setBtn2hover] = useState(false)

  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  const {
    description: description0,
    question: question0,
    iconSize: iconSize0,
    title: title0
  } = data[0].attributes
  const {
    description: description1,
    question: question1,
    iconSize: iconSize1,
    title: title1
  } = data[1].attributes
  const {
    description: description2,
    question: question2,
    iconSize: iconSize2,
    title: title2
  } = data[2].attributes
  const {
    description: description3,
    question: question3,
    iconSize: iconSize3,
    title: title3
  } = data[3].attributes

  const { url: iconUrl0 } = data[0].attributes.icon.data.attributes
  const { url: iconUrl1 } = data[1].attributes.icon.data.attributes
  const { url: iconUrl2 } = data[2].attributes.icon.data.attributes
  const { url: iconUrl3 } = data[3].attributes.icon.data.attributes

  const { url: imageUrl0 } = data[0].attributes.image.data.attributes
  const { url: imageUrl1 } = data[1].attributes.image.data.attributes
  const { url: imageUrl2 } = data[2].attributes.image.data.attributes
  const { url: imageUrl3 } = data[3].attributes.image.data.attributes

  const { url: maskUrl0 } = data[0].attributes.mask.data.attributes
  const { url: maskUrl1 } = data[1].attributes.mask.data.attributes
  const { url: maskUrl2 } = data[2].attributes.mask.data.attributes
  const { url: maskUrl3 } = data[3].attributes.mask.data.attributes

  const { href: href0 } = data[0].attributes
  const { href: href1 } = data[1].attributes
  const { href: href2 } = data[2].attributes
  const { href: href3 } = data[3].attributes

  return (
    <Container maxWidth={false} sx={{ overflow: 'hidden', mb: 10, pt: 10 }}>
      <Stack alignItems={'center'} sx={{ mb: 10 }}>
        <Typo26 sx={{ mt: 0, mb: '9.6px', mr: 0, ml: 0 }}>Lösungen</Typo26>

        <Typo32>KARMA hat die passenden Antworten auf Ihre Fragen</Typo32>
      </Stack>

      <Grid container spacing={10}>
        {/* Energiemonitoring */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'right',
            maxHeight: 750
          }}
        >
          <SolutionText
            href={href0}
            url={iconUrl0}
            size={iconSize0}
            question={question0}
            description={description0}
            title={title0}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'left',
            maxHeight: 750
          }}
        >
          <Box
            ref={refR}
            component={'img'}
            draggable={false}
            src={`${BASE_URL}${imageUrl0}`}
            sx={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              //position: 'absolute',
              //left: '600px',
              maskImage: `url(${BASE_URL}${maskUrl0})`,
              WebkitMaskImage: `url(${BASE_URL}${maskUrl0})`,
              maskSize: isGtMD ? 954 : 475,
              maskRepeat: 'no-repeat',
              maskPosition: 'center',
              height: { xs: '50%', md: 'auto' },
              /* @keyframes duration | easing-function | delay | iteration-count | direction | fill-mode | play-state | name */
              animation:
                isGtMD && isOnScreenR === true
                  ? `${moveInFromRightAnimation} .5s ease-out 0s 1 normal forwards running`
                  : null
            }}
          />
        </Grid>

        {/* Anlagenmonitoring */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'right'
          }}
        >
          <Box
            ref={refL}
            component={'img'}
            draggable={false}
            src={`${BASE_URL}${imageUrl1}`}
            sx={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              //position: 'absolute',
              //left: '-1150px',
              maskImage: `url(${BASE_URL}${maskUrl1})`,
              WebkitMaskImage: `url(${BASE_URL}${maskUrl1})`,
              maskSize: isGtMD ? 954 : 475,
              maskRepeat: 'no-repeat',
              maskPosition: 'center',
              height: { xs: '50%', md: 'auto' },
              animation:
                isGtMD && isOnScreenL === true
                  ? `${moveInFromLeftAnimation} .5s ease-out 0s 1 normal forwards running`
                  : null
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'left'
          }}
        >
          <SolutionText
            href={href1}
            url={iconUrl1}
            size={iconSize1}
            question={question1}
            description={description1}
            title={title1}
          />
        </Grid> 
      </Grid>

      {/* KARMA kann auf Wunsch ... */}
      <Stack alignItems={'center'} sx={{ mt: 10, mb: 20 }}>
        <Typo32>
          KARMA kann auf Wunsch noch mehr für Sie und die Nutzer Ihrer Gebäude
          tun
        </Typo32>
      </Stack>

      <Grid container spacing={10}>
        <Grid item
          xs={6}
          sx={{
            padding: '0 4% 0 8%'
          }}
        >
          <Box
            component={'img'}
            draggable={false}
            src={`${BASE_URL}${imageUrl2}`}
            sx={{
              maskImage: `url(${BASE_URL}${maskUrl2})`,
              maskRepeat: 'no-repeat',
              maskPosition: 'left',
              maskSize: '95%',
              width: '100%',
              height: 'auto',
              marginBottom: '25px'
            }}
          />

          <Typo32>{question2}</Typo32>
          <Typo20>{description2}</Typo20>

          <Button sx={{width: 260}}
                  onMouseEnter={() => setBtn2hover(true)}
                  onMouseLeave={() => setBtn2hover(false)}
                  onClick={() => history.push(`/loesungen${href2}`)}>

            <SVGPic
              src={`${BASE_URL}${iconUrl2}`}
              size={iconSize2}
              sx={{
                mr: 1,
                filter: btn2hover === true ? `brightness(48)` : `brightness(1)`
              }}
            />
            {title2}
          </Button>
        </Grid>

        <Grid item
          xs={6}
          sx={{
            padding: '0 8% 0 4%'
          }}
        >
          <Box
            component={'img'}
            draggable={false}
            src={`${BASE_URL}${imageUrl3}`}
            sx={{
              maskImage: `url(${BASE_URL}${maskUrl3})`,
              maskRepeat: 'no-repeat',
              maskPosition: 'left',
              maskSize: '95%',
              width: '100%',
              height: 'auto',
              marginBottom: '25px'
            }}
          />
          
          <Typo32>{question3}</Typo32>
          <Typo20>{description3}</Typo20>

          <Button sx={{width: 260}}
                  onMouseEnter={() => setBtn2hover(true)}
                  onMouseLeave={() => setBtn2hover(false)}
                  onClick={() => history.push(`/loesungen${href3}`)}>
            <SVGPic
              src={`${BASE_URL}${iconUrl3}`}
              size={iconSize3}
              sx={{
                mr: 1,
                filter: btn2hover === true ? `brightness(48)` : `brightness(1)`
              }}
            />
            {title3}
          </Button>
        </Grid>         
      </Grid>

      {/* Zu allen Lösungen */}
      <Stack alignItems={'center'} sx={{ width: 1, my: 10, marginLeft: 'auto', marginRight: 'auto' }}>
        <DZ_GotoPage
          href={'/loesungen'}
          sx={{
            //':hover': { animation: `${wobbleAnimation} .75s infinite ease` },
            mb: 1
          }}
          title={'Zu allen Lösungen'}
        />
      </Stack>
    </Container>
  )
}

HomeSolutionsDesktop.propTypes = {
  data: PropTypes.array.isRequired
}

const HomeSolutionsMobile = ({ data }) => {
  const history = useHistory()

  const {
    description: description0,
    question: question0,
    iconSize: iconSize0,
    title: title0
  } = data[0].attributes
  const {
    description: description1,
    question: question1,
    iconSize: iconSize1,
    title: title1
  } = data[1].attributes
  const {
    description: description2,
    question: question2,
    iconSize: iconSize2,
    title: title2
  } = data[2].attributes
  const {
    description: description3,
    question: question3,
    iconSize: iconSize3,
    title: title3
  } = data[3].attributes

  const { url: iconUrl0 } = data[0].attributes.icon.data.attributes
  const { url: iconUrl1 } = data[1].attributes.icon.data.attributes
  const { url: iconUrl2 } = data[2].attributes.icon.data.attributes
  const { url: iconUrl3 } = data[3].attributes.icon.data.attributes

  const { url: imageUrl0 } = data[0].attributes.image.data.attributes
  const { url: imageUrl1 } = data[1].attributes.image.data.attributes
  const { url: imageUrl2 } = data[2].attributes.image.data.attributes
  const { url: imageUrl3 } = data[3].attributes.image.data.attributes

  const { url: maskUrl0 } = data[0].attributes.mask.data.attributes
  const { url: maskUrl1 } = data[1].attributes.mask.data.attributes
  const { url: maskUrl2 } = data[2].attributes.mask.data.attributes
  const { url: maskUrl3 } = data[3].attributes.mask.data.attributes

  const { href: href0 } = data[0].attributes
  const { href: href1 } = data[1].attributes
  const { href: href2 } = data[2].attributes
  const { href: href3 } = data[3].attributes

  return (
    <Container maxWidth={false} sx={{ _overflow: 'hidden', mb: 10, pt: 10 }}>
      <Stack alignItems={'center'} sx={{ mb: 10, textAlign: 'center' }}>
        <Typo26Bold>
          KARMA hat die passenden Antworten auf Ihre Fragen
        </Typo26Bold>
      </Stack>

      <Grid container>
        {/* Energiemonitoring */}
        <Grid item xs={12}>
          <Stack alignItems={'start'}>
            <Box
              component={'img'}
              draggable={false}
              src={`${BASE_URL}${imageUrl0}`}
              sx={{
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                maskImage: `url(${BASE_URL}${maskUrl0})`,
                WebkitMaskImage: `url(${BASE_URL}${maskUrl0})`,
                maskRepeat: 'no-repeat',
                maskPosition: 'center',
                maskSize: 475,
                ml: -14,
                maxHeight: 380
              }}
            />

            <SolutionText
              href={href0}
              url={iconUrl0}
              size={iconSize0}
              question={question0}
              description={description0}
              title={title0}
            />
          </Stack>
        </Grid>

        {/* Anlagenmonitoring */}
        <Grid item xs={12}>
          <Stack alignItems={'start'}>
            <Box
              component={'img'}
              draggable={false}
              src={`${BASE_URL}${imageUrl1}`}
              sx={{
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                maskImage: `url(${BASE_URL}${maskUrl1})`,
                WebkitMaskImage: `url(${BASE_URL}${maskUrl1})`,
                maskRepeat: 'no-repeat',
                maskPosition: 'center',
                maskSize: 450,
                ml: -14,
                maxHeight: 380
              }}
            />
            <SolutionText
              href={href1}
              url={iconUrl1}
              size={iconSize1}
              question={question1}
              description={description1}
              title={title1}
            />
          </Stack>
        </Grid>
      </Grid>

      {/* KARMA kann auf Wunsch ... */}
      <Stack alignItems={'center'} sx={{ my: 10 }}>
        <Typo26Bold>
          KARMA kann auf Wunsch noch mehr für Sie und die Nutzer Ihrer Gebäude
          tun
        </Typo26Bold>
      </Stack>

      <Grid container>
        <Grid item xs={12}>
          <Stack alignItems={'start'}>
            <Box
              component={'img'}
              draggable={false}
              src={`${BASE_URL}${imageUrl2}`}
              sx={{
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                maskImage: `url(${BASE_URL}${maskUrl2})`,
                WebkitMaskImage: `url(${BASE_URL}${maskUrl2})`,
                maskRepeat: 'no-repeat',
                maskPosition: 'left',
                maskSize: 450,
                ml: -8,
                maxHeight: 380
              }}
            />
            <Typo32>{question2}</Typo32>
            <Typo20 sx={{ mb: 2 }}>{description2}</Typo20>
            <Button onClick={() => history.push(`/loesungen${href2}`)}>
              <SVGPic
                src={`${BASE_URL}${iconUrl2}`}
                size={iconSize2}
                sx={{ mr: 1 }}
              />
              {title2}
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Stack alignItems={'start'}>
            <Box
              component={'img'}
              draggable={false}
              src={`${BASE_URL}${imageUrl3}`}
              sx={{
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                maskImage: `url(${BASE_URL}${maskUrl3})`,
                WebkitMaskImage: `url(${BASE_URL}${maskUrl3})`,
                maskRepeat: 'no-repeat',
                maskPosition: 'left',
                maskSize: 450,
                ml: -8,
                maxHeight: 380
              }}
            />
            <Typo32>{question3}</Typo32>
            <Typo20 sx={{ mb: 2 }}>{description3}</Typo20>
            <Button onClick={() => history.push(`/loesungen${href3}`)}>
              <SVGPic
                src={`${BASE_URL}${iconUrl3}`}
                size={iconSize3}
                sx={{ mr: 1 }}
              />
              {title3}
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {/* Zu allen Lösungen */}
      <Stack alignItems={'center'} sx={{ width: 1, my: 10 }}>
        <DZ_GotoPage
          href={'/loesungen'}
          sx={{ mb: 1 }}
          title={'Zu allen Lösungen'}
        />
      </Stack>
    </Container>
  )
}

HomeSolutionsMobile.propTypes = {
  data: PropTypes.array.isRequired
}

export const HomeSolutions = ({ data }) => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return isGtMD ? (
    <HomeSolutionsDesktop data={data} />
  ) : (
    <HomeSolutionsMobile data={data} />
  )
}

HomeSolutions.propTypes = {
  data: PropTypes.array.isRequired
}
